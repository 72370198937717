<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- お客様の声 -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>30代・M.Kさん</heading></h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest1" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6" class="align-self-center">
                    <div
                        :class="smAndUp ? 'text-h4' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="smAndUp ? leadPc : leadSp"
                    ></div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest2" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q1:
                            指導を受けようと思ったきっかけはなんですか？(体験しようとしたきっかけ）
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            30歳を超えて「体力低下」「体型変化」を実感しました。
                            学生時代は文科系の部活動ばかりをしており「正しい運動方法」を知らなかったので、指導を受けようと決めました。
                        </div>
                        <div class="question my-4">
                            Q2:
                            指導を受ける前の体はどんな状態でしたか？どんな生活でしたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            好きな分だけ飲み食いして遊んでいましたね。
                            これらが日頃のストレス解消の手段になっていました。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest3" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q3:
                            結果が出た後（体が変わった後）見た目や生活はどう変わりましたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            体がスッキリし、着てみたい服やでかけたい場所がたくさん増えました。
                            規則正しい生活を遅れるようになりました。早寝早起きになったから、家で料理する機会も増えましたね。
                        </div>
                        <div class="question my-4">
                            Q4:
                            REVOISTの指導を受けたほうがいい人（オススメしたいひと）、逆に受けないほうが良い・必要ない人はどんなひとでしょうか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            自分のトレーニング方法をすでに確信していて、教えてもらわなくても良いという考えの方には不要だと思います。
                            現状を打開したいと考えながらも、自分に合った方法がわからない人にはオススメです。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest4" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q5:
                            指導を受けていなかったらどうなっていたと思いますか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            自身の生活や体型に迷いを持ち続け、
                            毎日がもっとつまならなくなっていたのではないでしょうか。
                        </div>
                        <div class="question my-4">
                            Q6:
                            指導を受けようか迷っている人へメッセージをお願いします。
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            「今の姿を変えたい」と感じている方は一度REVOISTに相談してみてください。
                            自分の眼で見て、感じて判断してください！
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Testimonial"
                        >お客様の声一覧に戻る</link-tag
                    >
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'お客様の声',
                    disabled: false,
                    href: 'testimonial',
                },
                {
                    text: '20代・A.Yさん',
                    disabled: false,
                    href: '',
                },
            ]
        },

        imageGuest1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/4B7A8859.png'
                : 'static/sp_testimonial–2/testimonial01_img01.png'
        },
        imageGuest2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/グループ 17.png'
                : 'static/sp_testimonial–2/testimonial01_img02.png'
        },
        imageGuest3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/グループ 58.png'
                : 'static/sp_testimonial–2/testimonial01_img03.png'
        },
        imageGuest4() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/4B7A8927.png'
                : 'static/sp_testimonial–2/testimonial01_img04.png'
        },

        leadPc() {
            return '着てみたい服や<br />出かけたい場所が<br />たくさん増えました！'
        },
        leadSp() {
            return '着てみたい服や 出かけたい場所が たくさん増えました！'
        },
    },
}
</script>

<style lang="scss" scoped>
.question {
    color: #1a0dab;
}
.answer {
    color: #c30013;
}
</style>
